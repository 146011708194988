<template>
  <div>
    <HeaderPanel
      title="Random Reward"
      :filter="filter"
      placeholder="Search Name"
      @search="getData"
      @sidebar="sidebarFilter"
      :hasDropdown="false"
      :hasFilter="false"
      routerPath="/random-reward/detail/0"
    />
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <Table
        :filter="filter"
        :fields="fields"
        :items="items"
        :isBusy="isBusy"
        :rows="rows"
        :showingTo="showingTo"
        @filterPage="filterPage"
      />
    </div>
  </div>
</template>
<script>
import HeaderPanel from "@/components/HeaderPanel";
import OtherLoading from "@/components/loading/OtherLoading";
import Table from "./components/Table";
import SideBarFilter from "@/components/SideBarFilter";

export default {
  components: {
    OtherLoading,
    HeaderPanel,
    SideBarFilter,
    Table,
  },
  data() {
    return {
      filter: {
        search: "",
        PageNumber: 1,
        RecordPerPage: 10,
        page: 1,
        take: 10,
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      fields: [
        {
          key: "name",
          label: "Name",
          class: "w-8",
        },

        {
          key: "active",
          label: "Status",
          class: "w-2",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      items: [],
      styleDatetime: {
        width: "150%",
        border: "none",
      },
    };
  },

  created() {
    this.getData();
  },
  methods: {
    getData() {
      let payload = { ...this.filter };
      payload.PageNumber = payload.page;
      payload.RecordPerPage = payload.take;
      this.isBusy = true;

      this.axios.post(`/RandomReward/get_list`, payload).then((res) => {
        if (res.data.result) {
          this.rows = res.data.detail.totalRecord;
          this.items = res.data.detail.random_reward;
          this.isBusy = false;
        }
      });
    },

    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },

    filterPage(filter) {
      this.filter = filter;
      this.getData();
    },
    dateFormat() {
      let today = new Date();
      return this.$moment(today).format();
    },
    clearFilter() {
      this.filter = {
        search: "",
        page: 1,
        take: 10,
        status: [],
      };
    },
  },
};
</script>
